var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_work_order") } },
    [
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: {
            model: _vm.form,
            "wrapper-col": { span: 12 },
            "label-col": { span: 8 },
            "label-align": "left"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_work_order_number"),
                        prop: "woNumber"
                      }
                    },
                    [
                      _c("SelectWorkOrderHeader", {
                        on: { "update:meta": _vm.onChangeWo },
                        model: {
                          value: _vm.form.woNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "woNumber", $$v)
                          },
                          expression: "form.woNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_document_reference"),
                        prop: "docReference"
                      }
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          "show-search": "",
                          "allow-clear": "",
                          loading: _vm.loading.docReference,
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_document_reference")
                          }),
                          "filter-option": false,
                          "label-in-value": "",
                          "data-testid": "wo-doc-ref",
                          options: _vm.docReferenceOptions
                        },
                        on: {
                          search: _vm.onSearchDocReference,
                          change: _vm.onChangeDocReference
                        },
                        model: {
                          value: _vm.form.docReference,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "docReference", $$v)
                          },
                          expression: "form.docReference"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_date"), prop: "date" } },
                    [
                      _c("a-range-picker", {
                        staticClass: "w-100",
                        attrs: {
                          ranges: {
                            Today: [_vm.moment(), _vm.moment()],
                            "This Month": [
                              _vm.moment(),
                              _vm.moment().endOf("month")
                            ]
                          },
                          format: _vm.DEFAULT_DATE_FORMAT,
                          type: "date"
                        },
                        on: { change: _vm.onChangeDate },
                        model: {
                          value: _vm.form.date,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "date", $$v)
                          },
                          expression: "form.date"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_customer_name"),
                        prop: "customer"
                      }
                    },
                    [
                      _c("SelectCustomer", {
                        on: { "update:meta": _vm.onChangeCustomer },
                        model: {
                          value: _vm.form.customer,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "customer", $$v)
                          },
                          expression: "form.customer"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_unit_code"),
                        prop: "unitCode",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("SelectUnitCode", {
                        on: { "update:meta": _vm.onChangeUnit },
                        model: {
                          value: _vm.form.unitCode,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "unitCode", $$v)
                          },
                          expression: "form.unitCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_branch"),
                        prop: "branch",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("SelectBranch", {
                        attrs: { "label-in-value": "" },
                        model: {
                          value: _vm.form.branch,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "branch", $$v)
                          },
                          expression: "form.branch"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_part_category"),
                        prop: "partCategory",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("SelectProductCategory", {
                        on: { "update:meta": _vm.onChangePartCategory },
                        model: {
                          value: _vm.form.partCategory,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "partCategory", $$v)
                          },
                          expression: "form.partCategory"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "danger" },
                          on: { click: _vm.handleReset }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.find,
                            "html-type": "submit"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.$can("create", "work-order")
                ? _c(
                    "a-col",
                    { attrs: { align: "end", span: 12 } },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { to: { name: "logistic.work-order.create" } }
                        },
                        [
                          _c("a-button", { attrs: { type: "primary" } }, [
                            _vm._v(" " + _vm._s(_vm.$t("lbl_create_new")) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          "data-source": _vm.dataSource.data,
          "row-key": "id",
          columns: _vm.columns,
          scroll: { x: 2300 },
          loading: _vm.loading.find,
          pagination: {
            showTotal: function() {
              return _vm.$t("lbl_total_items", {
                total: _vm.dataSource.totalElements
              })
            },
            showSizeChanger: true,
            total: _vm.dataSource.totalElements,
            pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
            current: _vm.pagination.page,
            defaultPageSize: _vm.pagination.limit
          },
          size: "small"
        },
        on: { change: _vm.onTableChange },
        scopedSlots: _vm._u([
          {
            key: "nullable",
            fn: function(text) {
              return [_vm._v(" " + _vm._s(text || "-") + " ")]
            }
          },
          {
            key: "number",
            fn: function(text) {
              return [_vm._v(" " + _vm._s(_vm._f("qty")(text)) + " ")]
            }
          },
          {
            key: "date",
            fn: function(text) {
              return [_vm._v(" " + _vm._s(_vm._f("date")(text)) + " ")]
            }
          },
          {
            key: "action",
            fn: function(text, ref) {
              var workOrderId = ref.workOrderId
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "logistic.work-order.detail",
                        params: { id: workOrderId }
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("lbl_view")) + " ")]
                )
              ]
            }
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "mt-3 text-right" },
        [
          _c(
            "a-space",
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.loading.print },
                  on: { click: _vm.handlePrint }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
              ),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.loading.download },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }