import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { DownloadRequestParam } from "@/models/interface/download";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  CreateSparePartRequestDto,
  SparePartDetailHeaderResDto,
  SparepartResponseDto,
} from "@/models/interface/sparepart-request";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class SparePartRequestService extends HttpClient {
  constructor() {
    super();
  }

  createSparepartRequest(payload: CreateSparePartRequestDto): Promise<void> {
    return this.post<void, CreateSparePartRequestDto>(
      Api.SparepartRequest,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  listSparepartRequest(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<SparepartResponseDto>> {
    return this.get<Pagination<SparepartResponseDto>>(Api.SparepartRequest, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadListSparepartRequests(params: DownloadRequestParam): Promise<Blob> {
    return this.get<Blob>(Api.SparepartRequestDownload, {
      responseType: "blob",
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetail(srfId: string): Promise<SparePartDetailHeaderResDto> {
    return this.get<SparePartDetailHeaderResDto>(
      `${Api.SparepartRequestDetail}/${srfId}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  reject(srfId: string): Promise<void> {
    return this.put<void>(`${Api.SparepartRequestReject}/${srfId}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const sparePartRequestService = new SparePartRequestService();
