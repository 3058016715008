





















































































































import ModalStateBuilder from "@/builder/ModalStateBuilder";
import CloseAmortizationModal from "@/components/Amortization/CloseAmortizationModal.vue";
import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import { useMinByDate } from "@/hooks/datepicker";
import MNotification from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  AmortizationDetailResponseDto,
  AmortizationDetailUnitResponseDto,
  CloseAmortizationForm,
  CloseAmortizationUnitTableState,
} from "@/models/interface/amortization";
import { amortizationService } from "@/services/amortization.service";
import { LabelInValue } from "@/types";
import { StringUtils } from "@/utils/StringUtils";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import { Component, Mixins, Ref } from "vue-property-decorator";

@Component({
  components: {
    CloseAmortizationModal,
  },
})
export default class CloseAmortization extends Mixins(MNotification) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  @Ref("formModel")
  formModel!: FormModel;

  columns = [
    {
      title: this.$t("lbl_no"),
      dataIndex: "no",
      key: "no",
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_acquisition_date"),
      dataIndex: "acquisitionDate",
      key: "acquisitionDate",
      customRender: text => dateFormat(text),
    },
    {
      title: this.$t("lbl_brand"),
      dataIndex: "brand",
      key: "brand",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      key: "type",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_note"),
      dataIndex: "note",
      key: "note",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_year"),
      dataIndex: "year",
      key: "year",
      customRender: text => text || "-",
    },
    {
      title: this.$t("lbl_start_period"),
      dataIndex: "startPeriod",
      key: "startPeriod",
      customRender: text => dateFormat(text),
    },
    {
      title: this.$t("lbl_end_period"),
      dataIndex: "endPeriod",
      key: "endPeriod",
      customRender: text => dateFormat(text),
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "amount",
      key: "amount",
      customRender: text => currencyFilter(text),
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      customRender: text => text || "-",
    },
  ];

  loading = {
    getDetail: false,
    close: false,
  };

  modal = ModalStateBuilder.build({});

  formRules = {
    unitCode: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
    closingDate: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
    description: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
  };

  formData: CloseAmortizationForm = this.initCloseAmortizationForm();

  amortizationDetail: AmortizationDetailResponseDto =
    new AmortizationDetailResponseDto();
  units: CloseAmortizationUnitTableState[] = [];

  get unitCodeOptions(): Option[] {
    const unitCodes: Set<string> = new Set();

    return this.amortizationDetail.units
      .filter(unit => {
        if (
          !unit.unitCode ||
          unitCodes.has(unit.unitCode) ||
          !StringUtils.compare(unit.status, "Open")
        ) {
          return false;
        }

        unitCodes.add(unit.unitCode);
        return true;
      })
      .map(unit => ({
        key: unit.unitId,
        label: unit.unitCode,
        value: unit.unitId,
      }));
  }

  created(): void {
    this.getAmortizationDetail(this.$route.params.id);
  }

  initCloseAmortizationForm(): CloseAmortizationForm {
    return {
      closingDate: null,
      description: undefined,
      unitCode: undefined,
    };
  }

  beforeStartPeriod(curr: Moment): boolean {
    return useMinByDate(curr, moment(this.amortizationDetail.startPeriod));
  }

  mapUnitsToTableState(
    units: AmortizationDetailUnitResponseDto[]
  ): CloseAmortizationUnitTableState[] {
    return units
      .flatMap(unit =>
        unit.periods.map(period => ({
          ...unit,
          ...period,
        }))
      )
      .map((unit, index) => ({
        ...unit,
        no: index + 1,
      }));
  }

  handleBack(): void {
    this.$router.push({
      name: "amortizations.detail",
      params: { id: this.$route.params.id },
    });
  }

  handleUnitCodeChange(unitCode: LabelInValue | undefined): void {
    if (!unitCode) {
      this.units = [];
      return;
    }

    const openAndSelectedUnits = this.amortizationDetail.units.filter(
      unit =>
        StringUtils.compare(unit.status, "Open") &&
        unit.unitCode === unitCode.label
    );
    this.units = this.mapUnitsToTableState(openAndSelectedUnits);
  }

  handleCloseAmortization(): void {
    this.formModel.validate(valid => {
      if (!valid) {
        return;
      }

      this.modal.open();
    });
  }

  closeAmortization(note: string): void {
    this.modal.close();

    if (!this.formData.unitCode?.key || !this.formData.closingDate) {
      this.showNotifError("notif_validation_error");
      return;
    }

    this.loading.close = true;
    amortizationService
      .closeAmortization(this.$route.params.id, {
        amortizationUnitId: this.formData.unitCode.key,
        closeDate: this.formData.closingDate.format(),
        closingNote: note,
        descriptionClose: this.formData.description || "",
      })
      .then(() => {
        this.showNotifSuccess("notif_amortization_close_success");
        this.handleBack();
      })
      .finally(() => {
        this.loading.close = false;
      });
  }

  getAmortizationDetail(id: string) {
    this.loading.getDetail = true;
    amortizationService
      .getAmortizationDetail(id)
      .then(response => {
        this.amortizationDetail = response;
      })
      .finally(() => {
        this.loading.getDetail = false;
      });
  }
}
