
















import { SearchBuilder } from "@/builder";
import Select from "@/components/custom/select/Select.vue";
import { debounceProcess } from "@/helpers/debounce";
import { useWorkOrder } from "@/hooks";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ONE } from "@/models/constant/global.constant";
import { WorkOrderResponseDto } from "@/models/interface/work-order";
import { RequestQueryParamsModel } from "@interface/http.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    Select,
  },
})
export default class SelectWorkOrderHeader extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<WorkOrderResponseDto>[] = [];
  loading = false;

  pagination = {
    page: FIRST_PAGE,
    search: "",
  };

  allLoaded = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions(this.buildParams());
  }

  fetchOptions(params: RequestQueryParamsModel): void {
    const { findAllWoHeader, toOption } = useWorkOrder();
    this.loading = true;
    findAllWoHeader(params)
      .then(res => {
        this.allLoaded = res.currentPage + ONE === res.totalPages;
        const copy = [...this.options];
        this.options = [...copy, ...toOption(res.data)];
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { findBy } = useWorkOrder();
    this.resetState();
    this.pagination.search = findBy({ documentNumber: val }).join(
      new SearchBuilder().AND
    );

    this.fetchOptions(this.buildParams());
  }

  findOption(value: string): Option<WorkOrderResponseDto> | undefined {
    return this.options.find(e => value === e.value);
  }

  buildParams(): RequestQueryParamsModel {
    const params = new RequestQueryParams();
    const { page, search } = this.pagination;
    params.page = page - ONE;
    params.search = search;
    return params;
  }

  onScrollEnd(): void {
    if (this.loading || this.allLoaded) return;
    this.pagination.page += ONE;
    this.fetchOptions(this.buildParams());
  }

  resetState(): void {
    this.pagination.page = ONE;
    this.options = [];
    this.allLoaded = false;
  }
}
